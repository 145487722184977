import axios from "axios";
import alertify from "alertifyjs";

class Rpc {
    axios;
    route;

    constructor(options) {
        this.route = options.route;
        this.axios = options.axios;
    }

    validate() {
        return this.execute('validate', {}, true);
    }

    auth(login, password) {
        return this.execute('auth', {
            login: login,
            password: password,
        }, true);
    }

    logout() {
        return this.execute('logout');
    }

    getCollectorAgencies(limit, offset) {
        return this.execute('getCollectorAgencies', {
            limit: +limit,
            offset: +offset,
        }, true);
    }

    createCollectorAgency(data) {
        return this.execute('createCollectorAgency', data);
    }

    changeCollectorActivity(id, isActive) {
        return this.execute('changeCollectorActivity', {
            id: +id,
            isActive: isActive,
        });
    }

    editCollectorAgency(id, data) {
        return this.execute('editCollectorAgency', {
            id,
            ...data,
        });
    }

    getBalanceInfo() {
        return this.execute('getBalanceInfo');
    }

    changeCollectorsBalance(data) {
        return this.execute('changeCollectorsBalance', {
            config: data,
        }, true);
    }

    getParameters() {
        return this.execute('getParameters');
    }

    updateParameter(code, value) {
        return this.execute('updateParameter', {
            code: code,
            value: value,
        });
    }

    getLoansTable(data) {
        return this.execute('getLoansTable', {
            surname: data.surname || null,
            name: data.name || null,
            patronymic: data.patronymic || null,
            loanId: data.loanId || null,
            phone: data.phone || null,
            limit: data.limit || 10,
            offset: data.offset || 0,
            sort: data.sort || {field: null, direction: 'DESC'},
        });
    }

    getLoan(id) {
        return this.execute('getLoan', {
            id: +id,
        }, true);
    }

    getLastCollectorReports() {
        return this.execute('getLastCollectorReports', {}, true);
    }

    getFedResInfo(limit, offset, loanId) {
        return this.execute('getFedResInfo', {
            limit: +limit,
            offset: +offset,
            loanId: (loanId === null || loanId === '' || loanId === 0) ? null : +loanId,
        }, true);
    }

    createFedResReport(type, limit) {
        return this.execute('createFedResReport', {
            type: type,
            limit: +limit,
        });
    }

    getCollectorReports(agency, type, limit, offset) {
        return this.execute('getCollectorReports', {
            agency: agency === null ? null : +agency,
            type: type === null ? null : type,
            limit: +limit,
            offset: +offset,
        });
    }

    resetPassword(id) {
        return this.execute('resetPassword', {
            id: +id,
        }, true);
    }

    getNoInnLoans(limit, offset) {
        return this.execute('getNoInnLoans', {
            limit: +limit,
            offset: +offset,
        }, true);
    }

    getLoanHistory(loanId, limit, offset) {
        return this.execute('getLoanHistory', {
            loanId: +loanId,
            limit: +limit,
            offset: +offset,
        }, true);
    }

    getAdmins(limit, offset) {
        return this.execute('getAdmins', {
            limit: +limit,
            offset: +offset,
        });
    }

    createAdmin(login, password, adminPass, limit) {
        return this.execute('createAdmin', {
            login: login,
            password: password,
            adminPass: adminPass,
            limit: +limit,
        });
    }

    resetAdminPassword(userId, adminPass) {
        return this.execute('resetAdminPassword', {
            userId: +userId,
            adminPass: adminPass === null ? '' : adminPass,
        });
    }

    changeAdminActivity(userId, adminPass, limit, offset) {
        return this.execute('changeAdminActivity', {
            userId: +userId,
            adminPass: adminPass === null ? '' : adminPass,
            limit: +limit,
            offset: +offset,
        });
    }

    getErrorSendActions(limit, offset) {
        return this.execute('getErrorSendActions', {
            limit: +limit,
            offset: +offset,
        }, true);
    }

    getErrorMessages(limit, offset, messageId, reportId) {
        return this.execute('getErrorMessages', {
            limit: +limit,
            offset: +offset,
            messageId: messageId === null || messageId === '' ? null : +messageId,
            reportId: reportId === null || reportId === '' ? null : +reportId,
        }, true);
    }

    resendMessage(messageId) {
        return this.execute('resendMessage', {
            messageId: +messageId,
        }, true);
    }

    getBorrowerMessages(limit, offset, status, borrowerUuid, loanId) {
        return this.execute('getBorrowerMessages', {
            limit: +limit,
            offset: +offset,
            status: status,
            borrowerUuid: borrowerUuid,
            loanId: loanId === null || loanId === '' ? null : +loanId,
        }, true);
    }

    getBorrowerMessage(id) {
        return this.execute('getBorrowerMessage', {
            id: +id,
        });
    }

    repeatBorrowerMessage(id) {
        return this.execute('repeatBorrowerMessage', {
            id: +id,
        }, true);
    }

    createCollectorPaymentReport(dateFrom, dateTo, agency) {
        return this.execute('createCollectorPaymentReport', {
            dateFrom: dateFrom === null ? '' : dateFrom,
            dateTo: dateTo === '' ? null : dateTo,
            agency: agency === null ? null : +agency,
        }, true);
    }

    getCollectorPaymentReports(limit, offset, agency) {
        return this.execute('getCollectorPaymentReports', {
            limit: +limit,
            offset: +offset,
            agency: agency === null || agency === '' ? null : +agency,
        }, true);
    }

    getCollectorDebtSumReports(limit, offset, agency) {
        return this.execute('getCollectorDebtSumReports', {
            limit: +limit,
            offset: +offset,
            agency: agency === null || agency === '' ? null : +agency,
        }, true);
    }

    createDebtSumReport(agency, dateFrom, dateTo) {
        return this.execute('createDebtSumReport', {
            agency: agency,
            dateFrom: dateFrom,
            dateTo: dateTo,
        }, true);
    }

    execute(method, params = {}, throwRejection = false) {
        return new Promise((resolve, reject) => {
            this.axios.post(this.route, {
                jsonrpc: "2.0",
                id: "test",
                method: method,
                params: params,
            }, {withCredentials: true}).then(response => {
                response = response.data;
                if (response.error) {
                    if (throwRejection) {
                        reject(response.error);

                        return;
                    }
                    alertify.error(response.error.message ?? "Неизвестная ошибка");
                    resolve(response.error);

                    return;
                }
                resolve(response.result);
            });
        });
    }
}

export default new Rpc({
    route: '/rpc/v1',
    axios: axios
});
