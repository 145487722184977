<template>
  <div>
    <b-button :variant="variant" @click="logout">{{ text }}</b-button>
  </div>
</template>

<script>
import rpc from "../rpc";

export default {
  name: "Logout",
  props: {
    variant: {
      type: String,
      required: false,
      default: 'warning',
    },
    text: {
      type: String,
      required: false,
      default: 'Выход',
    }
  },
  methods: {
    logout() {
      rpc.logout().then(() => {
        window.location.reload();
      });
    },
  },
};
</script>

<style scoped>

</style>