<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="3"></b-col>
        <b-col sm="6">
          <b-card title="Авторизация">
            <b-card-text>
              <b-form-group label="Логин">
                <b-form-input type="text" v-model="login" placeholder="Введите логин"></b-form-input>
              </b-form-group>
              <b-form-group label="Пароль">
                <b-form-input type="password" v-model="password" placeholder="Введите пароль"></b-form-input>
              </b-form-group>
            </b-card-text>
            <b-card-footer>
              <b-button variant="success" @click="auth">Войти</b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import rpc from "../rpc";
import User from "../mixins/user";

export default {
  name: "Auth",
  data() {
    return {
      login: null,
      password: null,
    };
  },
  methods: {
    auth() {
      rpc.auth(this.login, this.password).then((result) => {
        let user = new User(result);

        this.$emit('input', user);
        this.$store.user = user;
      }).catch((error) => {
        switch (error.code) {
          case -32602:
            this.$alertify.error("Переданы неправильные данные");
            break;
          case -32603:
            this.$alertify.error("Внутренняя ошибка");
            break;
          case -32000:
            if (typeof error.data === "string") {
              this.$alertify.error(error.data);
            } else {
              this.$alertify.error("Неправильный логин или пароль");
            }
            break;
          default:
            this.error = error.message;
        }
      });
    },
  },
};
</script>

<style scoped>

</style>