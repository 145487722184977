<template>
  <div id="app">
    <div v-if="!loading">
      <div v-if="!user.authorized()">
        <auth v-model="user"></auth>
      </div>
      <div v-if="user.authorized()">
        <navigation></navigation>
        <router-view></router-view>
      </div>
    </div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import rpc from "./rpc";
import User from "./mixins/user";
import Auth from "./components/Auth";
import Navigation from "./components/Navigation";

export default {
  name: 'App',
  components: {Navigation, Auth},
  data() {
    return {
      loading: true,
      user: new User(),
    };
  },
  mounted() {
    rpc.validate().then((result) => {
      this.user = new User(result);
      this.$store.user = this.user;
    }).catch((error) => {
      if (error.code !== -32000) {
        this.$alertify.error("Неизвестная ошибка. Повторите попытку позднее.");
      }
    }).finally(() => {
      this.loading = false;
    });
  },
};
</script>
