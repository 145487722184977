export default class Collector {
    name;
    email;
    phone;
    inn;
    ogrn;
    address;

    constructor(data = {}) {
        this.name = data.name || null;
        this.email = data.email || null;
        this.phone = data.phone || null;
        this.inn = data.inn || null;
        this.ogrn = data.ogrn || null;
        this.address = data.address || null;
    }
}
