<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand></b-navbar-brand>
      <b-navbar-brand @click="toMain">Внешний коллектор<span v-if="additionalBrand"> | {{ additionalBrand }}</span></b-navbar-brand>
      <b-navbar-nav class="ms-auto">
        <logout></logout>
      </b-navbar-nav>
      <b-navbar-brand></b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
import Logout from "./Logout";
export default {
  name: "Navigation",
  components: {Logout},
  methods: {
    toMain() {
      this.$router.push('/');
    },
  },
  computed: {
    additionalBrand: function () {
      let user = this.$store.user;
      let result = null;
      if (typeof user === 'object' && !!user) {
        result = user.isAdmin ? 'Администратор' : user.collector.name;
      }
      return result;
    },
  },
};
</script>

<style scoped>

</style>