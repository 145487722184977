import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)
export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'main',
            component: () => import('./views/Main')
        },
        {
            path: '/loan/:id',
            name: 'loan',
            component: () => import('./views/CollectorLoan')
        }
    ],
});